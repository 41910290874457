import React, { ReactNode } from "react";
import { Icon } from "#app/components/ui/icon.tsx";
import { Link } from "@remix-run/react";

interface TitleProps {
	title: string;
	redirectTo: string;
}
export const Title: React.FC<TitleProps> = ({ title, redirectTo = "/" }) => {
	return (
		<Link to={redirectTo} className="flex items-center flex-none">
			<div className="flex-none bg-[#F4EBFE] hover:bg-[#9D21E6]/20 flex justify-center items-center rounded-full w-8 h-8 lg:w-10 lg:h-10 p-1">
				<Icon name="keyboard-arrow-left" size="xl" className="text-[#9D21E6]" />
			</div>
			<h1 className="ml-2 lg:ml-4 text-lg sm:text-xl md:text-2xl font-bold text-[#9D21E6] self-center truncate line-clamp-1 max-w-[350px]">
				{title}
			</h1>
		</Link>
	);
};

interface TitleWithSearchProps {
	title: string;
	redirectTo: string;
	search: string;
	setSearch: React.Dispatch<React.SetStateAction<string>>;
	children?: ReactNode;
}

export const TitleWithSearch: React.FC<TitleWithSearchProps> = ({
	title,
	redirectTo,
	search,
	setSearch,
	children,
}) => {
	const [isFocused, setIsFocused] = React.useState(false);

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleBlur = () => {
		setIsFocused(false);
	};

	return (
		<div className="flex items-center space-x-2 w-full justify-between mb-5 pt-3 h-[40px] mt-1.5">
			{!isFocused && <Title title={title} redirectTo={redirectTo} />}
			<div
				className={`relative flex items-center duration-300 ease-in-out ${
					isFocused ? "w-full" : "w-[150px]"
				}`}
			>
				<div
					className={`relative flex-1 flex items-center  border border-gray-300 rounded-md ${
						isFocused ? "w-full" : "w-[100px]"
					}`}
				>
					{/* Search Input */}
					<input
						type="search"
						name="search"
						id="searchInput"
						placeholder="Search"
						className="w-full pl-8 pr-2 py-2 outline-none rounded-lg text-sm"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						onFocus={handleFocus}
						onBlur={handleBlur}
						autoComplete="off"
					/>
					{/* Search Button */}
					<Icon
						name="search"
						size="lg"
						className="text-gray-600 absolute left-2"
					/>
				</div>
				{children && !isFocused && children}
			</div>
		</div>
	);
};
